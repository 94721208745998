import React, { useEffect, useState } from "react";
import bg from "../Assets/h2.png";
import sliderbg from "../Assets/sliderbg.jpeg";
import tabbg from "../Assets/h6.png";
import h1 from "../Assets/h1.png";
import h3 from "../Assets/h3.png";
import hbg1 from "../Assets/hbg1.png";
import hbg2 from "../Assets/hbg2.png";
import hbg3 from "../Assets/hbg3.png";
import d1 from "../Assets/h4.png";
import d2 from "../Assets/h5.png";
import sts from "../Assets/status.png";
import b1 from "../Assets/b1.jpeg";
import b2 from "../Assets/b2.jpeg";
import b3 from "../Assets/b3.jpeg";
import Button from "../Components/Buttons/Button";
import icon from "../Assets/icon.png";
import comit from "../Assets/h7.png";
import TestimonialSlider from "../Components/Testimonial/Testimonial";
import BlogCard from "../Components/Cards/BlogCards";
import { fetchHomeData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import StatsSection from "../Components/Count/Count";
import Loader from "../Components/Loader/Loader";
import LazyLoad from "react-lazyload";
import HorizontalSlider from "../Components/Slider/ProductSlider";

const Home = ({ sts }) => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchHomeData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching home data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}

      {data?.homeBanner && (
        <div
          className="  hidden md:block  relative lg:min-h-screen   bg-cover bg-center bg-no-repeat  "
          style={{ backgroundImage: `url(${data.homeBanner.bg_image_url})` }}
        >
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2   lg:mt-[70px]  w-[85%] mx-auto flex items-center justify-between md:space-x-8 flex-col-reverse md:flex-row">
            <div className="my-auto md:w-[50%] ">
              {" "}
              <h1 className="text-[40px] font-semibold">
                {data.homeBanner.title}
              </h1>{" "}
              <p className="py-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.homeBanner.description,
                  }}
                />
              </p>
              <Button
                to={data.homeBanner.button_url}
                text={data.homeBanner.button_text}
              />
            </div>
            <div className="md:w-[50%] mx-auto   mb-6 md:mb-0">
              {" "}
              <LazyLoad height={200}>
                <img
                  src={data.homeBanner.banner_url}
                  alt=""
                  className="w-full"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      )}

      {data?.homeBanner && (
        <div
          className=" block md:hidden  relative lg:min-h-screen   bg-cover bg-center bg-no-repeat  "
          style={{ backgroundImage: `url(${data.homeBanner.bg_image_url})` }}
        >
          <div className="w-[85%] mx-auto pt-[200px] pb-10">
            <div className="my-auto md:w-[50%] ">
              {" "}
              <h2 className="text-[30px] font-semibold">
                {data.homeBanner.title}
              </h2>{" "}
              <p className="py-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.homeBanner.description,
                  }}
                />
              </p>
              <Button
                to={data.homeBanner.button_url}
                text={data.homeBanner.button_text}
              />
            </div>
            <div className="md:w-[50%] mx-auto pt-10  mb-6 md:mb-0">
              {" "}
              <LazyLoad height={200}>
                <img
                  src={data.homeBanner.banner_url}
                  alt=""
                  className="w-full"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      )}
      {data?.whoWeAre && (
        <div className="w-[85%] mx-auto py-16 md:flex items-center md:space-x-8">
          <div className="md:w-1/2">
            <LazyLoad height={200}>
              <img
                src={data.whoWeAre.round_image_url}
                alt=""
                className="w-full"
              />
            </LazyLoad>
          </div>

          <div className="md:w-1/2">
            <div className="flex items-baseline">
              <h1 className="text-3xl w-1/2  font-semibold">
                {data.whoWeAre.title}
              </h1>
              <LazyLoad height={200}>
                <img
                  src={data.whoWeAre.image_url}
                  alt=""
                  className="h-[100px]  w-1/2"
                />
              </LazyLoad>
            </div>
            <p className="py-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.whoWeAre.description,
                }}
              />
            </p>

            <Button
              to={data.whoWeAre.button_url}
              text={data.whoWeAre.button_text}
            />
          </div>
        </div>
      )}
      {data?.divisions?.length > 0 && (
        <div className="bg-[#FFF5F5]">
          <div className="w-[85%] mx-auto  py-16">
            {data?.divisionContent && (
              <div>
                <h3 className="text-3xl font-semibold">
                  {data.divisionContent.title}
                </h3>

                <div
                  dangerouslySetInnerHTML={{
                    __html: data.divisionContent.description,
                  }}
                />
              </div>
            )}
            {data?.divisions?.length > 0 && (
              <div>
                {data.divisions.map((division, index) => (
                  <div
                    key={division.id}
                    className={`md:flex py-8 ${
                      index % 2 === 0 ? "" : "md:flex-row-reverse"
                    }`}
                  >
                    {/* Image section */}
                    <div
                      className={`md:w-[40%] ${
                        index % 2 === 0 ? "md:mr-10" : "md:ml-10"
                      }`}
                    >
                      <LazyLoad height={200}>
                        <img
                          src={division.image_url} // Use a default image if no image is provided
                          alt={division.title}
                          className="h-[350px] w-full object-cover rounded-lg"
                        />
                      </LazyLoad>
                    </div>

                    {/* Content section */}
                    <div className="md:w-[70%] my-auto pt-6 md:pt-0">
                      <div>
                        <h4 className="text-2xl">{division.title}</h4>
                        <p className="py-3">
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: division.project_description,
                            }}
                          />
                        </p>

                        {/* Sub menus */}
                        {division.sub_menus?.length > 0 && (
                          <div className=" md:w-[70%]  grid grid-cols-2 justify-items-start ">
                            {division.sub_menus.map((submenu) => (
                              <div
                                key={submenu.id}
                                className="flex items-center py-3"
                              >
                                <LazyLoad height={200}>
                                  <img
                                    src={icon} // Placeholder for submenu icons
                                    alt={submenu.title}
                                    className="w-[15px] h-[15px] mr-3"
                                  />
                                </LazyLoad>
                                <p className="uppercase text-red">
                                  {submenu.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* Button to view more */}
                        <Button
                          to={`/division/${
                            division.sub_menus?.[0]?.short_url || ""
                          }`}
                          text="View More"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {data?.cta && (
        <div
          className="min-h-screen-height bg-cover bg-left-top lg:h-[350px] bg-no-repeat flex items-center justify-center"
          style={{
            backgroundImage: `url(${data.cta.bg_image_url})`,
            backgroundSize: 400,
          }}
        >
          <div className="w-[85%] mx-auto shadow-2xl text-center py-6   bg-white bg-opacity-60 rounded-lg">
            <div className="w-[70%] mx-auto">
              <h3 className="text-3xl font-semibold ">{data.cta.title}</h3>
              <div
                className="py-6"
                dangerouslySetInnerHTML={{
                  __html: data.cta.description,
                }}
              />
              <Button to={data.cta.button_url} text={data.cta.button_text} />
            </div>
          </div>
        </div>
      )}

      {data?.keyFeatures?.length > 0 && sts && (
        <StatsSection keyFeatures={data?.keyFeatures} img={sts} />
      )}

      {data?.homeAbout && (
        <div className="w-[85%] mx-auto py-16 ">
          <div className="md:flex justify-between items-center">
            <div className="md:w-[40%]">
              <LazyLoad height={200}>
                <img src={comit} alt="" className="md:w-[80%]  " />
              </LazyLoad>
            </div>

            <div className="md:w-[60%] relative z-10  ">
              <h2 className="text-3xl capitalize py-3 font-semibold">
                {data.homeAbout.title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.homeAbout.description,
                }}
              />

              <div className="absolute bottom-[-50px]  ">
                <LazyLoad height={200}>
                  <img
                    src={data.homeAboutseal_image_url}
                    alt={data.homeAbout.title}
                    className="-rotate-12 opacity-10 w-[100px] h-[100px] object-cover"
                  />
                </LazyLoad>
              </div>

              <div className="absolute lg:top-[70%] hidden lg:block  bottom-0 -z-50 right-[-70px]">
                <LazyLoad height={200}>
                  <img
                    src={data.homeAbout.alternate_image_url}
                    alt={data.homeAbout.title}
                    className="   w-[300px]  opacity-30 object-cover"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      )}

      {data?.products?.length > 0 && (
        <div className="w-[75%] md:w-[85%] mx-auto mb-10 ">
          <h2 className="text-3xl text-center py-6 font-semibold">
            Our Products
          </h2>{" "}
          <HorizontalSlider products={data.products} />
        </div>
      )}
    </div>
  );
};

export default Home;
