import React, { useState } from "react";
import BlogCard from "../Cards/BlogCards";

const BlogSection = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(Object.keys(data?.blogs)[0]); // Initially null until data is fetched

  let tabs = [];
  if (data?.blogs) {
    tabs = Object.keys(data.blogs);
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const filteredPosts = data?.blogs[selectedTab];
  return (
    <div>
      <div className="flex  mb-8">
        {tabs?.map((tab) => (
          <button
            key={tab}
            className={`  py-1 px-4 text-center text-sm font-medium   transition-colors duration-300 ease-in-out ${
              selectedTab === tab
                ? "bg-red text-white rounded-md"
                : "border-transparent text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredPosts?.map((post) => (
          <BlogCard
            key={post.id}
            imageSrc={post.image_url}
            title={post.title}
            readTime={post.readTime}
            date={post.posted_date_formated}
            url={post.short_url}
            tag={post.tag}
            description={post.short_description}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
