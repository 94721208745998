import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const AntibioticsCard = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {data?.map((antibiotic) => (
        <NavLink
          key={antibiotic.id}
          to={`/division/${antibiotic.short_url}`}
          className="bg-white rounded-lg shadow-lg border border-gray-300 p-4 overflow-hidden transform hover:-translate-y-2 transition-transform duration-300"
        >
          <img
            src={antibiotic.image_url}
            alt={antibiotic.title}
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="pt-4">
            <h3 className="text-lg font-semibold">{antibiotic.title}</h3>
            <p className="text-gray-700 my-1">{antibiotic.tag}</p>
            <div className="flex items-center font-semibold">
              <span className="mr-3 text-red">Explore</span>
              <FaArrowRightLong className="text-red" />
            </div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default AntibioticsCard;
