import React, { useEffect, useState } from "react";
import antibio from "../Assets/antibio.jpeg";
import antibiobg from "../Assets/aboutbg.png";
import AntibioticsCard from "../Components/Cards/Antibiotics";
import { useLocation, useParams } from "react-router-dom";
import { fetchDivisionData } from "../Api/Api";
import MedicineDetail from "./MedicineDetail";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import MetaHelmet from "../Components/MetaData/MetaData";
const Antibiotics = () => {
  const { divisionId } = useParams();
  const [data, setData] = useState(null);
  const path = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchDivisionData(divisionId);
        setData(result);
        // Now that data is loaded, find the blog
        setIsLoading(false);
        // Set other blogs excluding the selected one
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching blogs details data:", error);
      }
    };

    loadData();
  }, [divisionId]); // Dependency on blogId
  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: data?.division?.title, path: path },
  ];

  return (
    <div>
       {data && <MetaHelmet metaData={data?.meta_data} />}
      {data?.subDivisions?.length > 0 ? (
        <div>
          {data?.banner && (
            <div className="relative lg:mt-[100px] mt-[150px] h-[60vh] overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
              <img
                src={data.banner.banner_url}
                alt=""
                className=" object-cover h-full md:h-auto   w-full"
              />
              <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
                <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">
                  {data?.division?.title}
                </h1>
                <Breadcrumb links={breadcrumbLinks} />
              </div>
              <img
                src={antibiobg}
                alt=""
                className="absolute bottom-[-10px] object-cover  w-full"
              />
            </div>
          )}
          <div className="w-[85%] mx-auto py-16">
            <h3 className="text-3xl font-semibold mb-8">
              {data?.division?.title}
            </h3>
            {data?.subDivisions?.length > 0 && (
              <AntibioticsCard data={data.subDivisions} />
            )}
          </div>{" "}
        </div>
      ) : (
        <MedicineDetail
          related={data?.relatedDivisions}
          data={data?.division}
          banner={data?.banner}
          metaData={data?.meta_data}
        />
      )}
    </div>
  );
};

export default Antibiotics;
