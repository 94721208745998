import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({ links }) => {
  return (
    <nav className="   py-3 rounded-md w-full">
      <ol className="list-reset flex justify-center">
        {links.map((item, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && <p className="px-1 text-white">/</p>}
            <NavLink
              to={item.path}
             
            >
             <span className={`text-white text-base hover:underline ${
                index === links.length - 1 ? "font-bold" : ""
              }`}>{item.name} </span> 
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
