import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";




const HorizontalSlider = ({ products }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full  ">
      <Slider {...settings}>
        {products?.map((item) => (
          <NavLink to={`/division/${item.short_url}`}>
            <div key={item.id} className="p-2 ">
              
                <img
                  src={item.image_url}
                  alt={item.title}
                  className="  object-contain pb-3 md:w-[80%] w-full h-[200px]"
                />
              
            </div>
          </NavLink>
        ))}
      </Slider>
    </div>
  );
};

export default HorizontalSlider;
