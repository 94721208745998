import React from "react";
import antibio from "../Assets/antibio.jpeg";
import antibiobg from "../Assets/aboutbg.png";
import DetailSlider from "../Components/Slider/DetailSlider";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import antibio1 from "../Assets/antibio1.jpeg";
import { NavLink, useLocation } from "react-router-dom";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import MetaHelmet from "../Components/MetaData/MetaData";

const MedicineDetail = ({ data, banner, related, metaData }) => {
  const path = useLocation();

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: data?.title, path: path },
  ];

  return (
    <div>
      {metaData && <MetaHelmet metaData={metaData} />}
      {banner && (
        <div className="relative lg:mt-[100px] mt-[150px]  h-[60vh] overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={banner?.banner_url}
            alt=""
            className=" object-cover h-full md:h-auto   w-full"
          />
          <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">
              {data?.title}
            </h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <img
            src={antibiobg}
            alt=""
            className="absolute bottom-[-10px] object-cover  w-full"
          />
        </div>
      )}
      {data && (
        <div className="w-[85%] mx-auto pt-16">
          <h3 className="text-3xl font-semibold mb-2">{data?.title}</h3>
          <p className="mb-4">{data?.tag}</p>
          <div className="md:flex  mb-10">
            <div className="lg:w-[30%] md:w-[50%]">
              {data?.galleries?.length > 0 && (
                <DetailSlider images={data?.galleries} />
              )}
            </div>
            {data?.specifications && (
              <div className="md:w-[70%]">
                <div className="md:w-[90%] mt-4 md:mt-0 mx-auto">
                  {data.specifications.map((item) => (
                    <div>
                      <h2 className="text-base pb-1">{item.spec_title}</h2>
                      <div
                        className="pb-5"
                        dangerouslySetInnerHTML={{
                          __html: item.spec_description,
                        }}
                      />
                    </div>
                  ))}
                  {/* <h2 className="text-base pb-4">Salt Composition</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.project_details,
                    }}
                  />
                  {data?.project_description && (
                    <div>
                      <h2 className="text-base py-4">Description</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.project_description,
                        }}
                      />
                    </div>
                  )} */}

                  {data?.project_description && (
                    <div>
                      <h2 className="text-base pb-1">Description</h2>
                      <div
                        className="pb-5"
                        dangerouslySetInnerHTML={{
                          __html: data?.project_description,
                        }}
                      />
                    </div>
                  )}
                 
                    <div className="flex space-x-4 my-6">
                      {/* WhatsApp Button */}
                      <a
                        href="https://web.whatsapp.com/send?phone=7591914433&text=Hello,%20how%20are%20you?"
                        className="flex items-center justify-center px-4 py-2 border border-gray-400 rounded-lg w-[130px] hover:bg-green-100 transition-colors duration-300"
                      >
                        <FaWhatsapp className="h-5 w-5 text-green-600 mr-2" />
                        <span className="text-black">WhatsApp</span>
                      </a>

                      {/* Email Button */}
                      <a
                        href="mailto:info@getbiotec.com"
                        className="flex items-center justify-center px-4 py-2 border w-[130px] border-gray-400 rounded-lg hover:bg-rose-100 transition-colors duration-300"
                      >
                        <FaEnvelope className="h-5 min-w-5   text-red mr-2" />
                        <span className="text-black">Email</span>
                      </a>
                    </div>
                 
                </div>
              </div>
            )}{" "}
          </div>

          {data?.how_to_use && (
            <div>
              <hr />

              <div className="py-4">
                <h4 className="text-xl font-semibold mb-2">How To Use</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.how_to_use,
                  }}
                />
              </div>
            </div>
          )}
          {data?.important_points && (
            <div>
              <hr />
              <div className="py-4">
                <h4 className="text-xl font-semibold mb-2">Important Points</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.important_points,
                  }}
                />
              </div>

              <hr />
            </div>
          )}
        </div>
      )}
      {related?.length > 0 && (
        <div className="w-[85%] mx-auto pt-8 pb-16">
          <h3 className="text-3xl font-semibold mt-4 mb-8">Related Products</h3>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 ">
            {related?.map((antibiotic) => (
              <NavLink
                key={antibiotic.id}
                to={`/division/${antibiotic.short_url}`}
                className="bg-white rounded-lg shadow-lg border border-gray-300 p-4 overflow-hidden transform hover:-translate-y-2 transition-transform duration-300"
              >
                <img
                  src={antibiotic.image_url}
                  alt={antibiotic.title}
                  className="w-full h-48 object-cover rounded-lg"
                />
                <div className="pt-4">
                  <h3 className="text-lg font-semibold">{antibiotic.title}</h3>
                  <p className="text-gray-700 my-1">{antibiotic.tag}</p>
                  <div className="flex items-center font-semibold">
                    <span className="mr-3 text-red">Explore</span>
                    <FaArrowRightLong className="text-red" />
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicineDetail;
