import React from "react";
import { NavLink } from "react-router-dom";

const BlogCard = ({
  imageSrc,
  title,
  readTime,
  date,
  description,
  url,
  tag,
}) => {
  return (
    <NavLink
      to={`/blog/${url}`}
      className="bg-white border rounded-lg shadow-md overflow-hidden transform hover:-translate-y-2 transition-transform duration-300 flex flex-col"
    >
      <img className="w-full h-56 object-cover" src={imageSrc} alt={title} />

      <div className="p-6 flex flex-col flex-grow">
        <div className="text-gray-600 text-sm mb-2">
          <span className="text-red mr-3">{tag}</span> {readTime}
        </div>
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <div className="text-gray-800 text-sm mb-4 flex-grow">
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        <div className="flex justify-between flex-col">
          <p className="py-2 text-sm text-gray-600">{date}</p>
          <span className="text-red hover:underline">Read More</span>
        </div>
      </div>
    </NavLink>
  );
};

export default BlogCard;
