import BlogCard from "../Components/Cards/BlogCards";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchBlogData, fetchBlogDetailData } from "../Api/Api";
import Loader from "../Components/Loader/Loader";

const BlogDetail = () => {
  const { blogId } = useParams();

  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const result = await fetchBlogDetailData(blogId);
        setData(result);
        // Now that data is loaded, find the blog
        setIsLoading(false)
        // Set other blogs excluding the selected one
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching blogs details data:", error);
      }
    };

    loadData();
  }, [blogId]); // Dependency on blogId
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div className=" bg-white bg-right-top  bg-no-repeat  py-8">
        <div className="w-[85%] mx-auto ">
          <h1 className="text-3xl font-bold   uppercase my-10">
            {data?.blog?.title}
          </h1>
          <div className="md:h-[400px] h-[300px] overflow-hidden rounded-lg">
            <img
              className="w-full     "
              src={data?.blog?.image_url}
              alt={data?.blog?.title}
            />
          </div>

          <div className="md:flex justify-between items-center mt-4 text-gray-800">
            <div className="flex items-center">
              <p className="uppercase text-red mr-4">{data?.blog?.tag}</p>
              <p className="m-0">{data?.blog?.readTime}</p>
            </div>
            <div className="flex items-center ">
              <p className="text-gray-800   text-sm mr-4">
                {data?.blog?.posted_date_formated}
              </p>

              <div className="flex items-center space-x-4  ">
                <a
                  href="/https://in.linkedin.com/"
                  target="_blank"
                  className="hover:text-gray-400"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://www.instagram.com/accounts/login/?hl=en"
                  target="_blank"
                  className="hover:text-gray-400"
                >
                  <FaInstagram />
                </a>

                <a
                  href="https://x.com/?lang=en"
                  target="_blank"
                  className="hover:text-gray-400"
                >
                  <FaXTwitter />
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="mb-8" />
        <div className="w-[85%] mx-auto  ">
          <div className="mt-10">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.blog?.description,
              }}
            />
          </div>
        </div>

        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl mb-8 font-semibold"> Related Articles</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-8">
            {data?.otherBlogs?.map((post) => (
              <BlogCard
                key={post.id}
                imageSrc={post.image_url}
                title={post.title}
                readTime={post.readTime}
                date={post.posted_date_formated}
                url={post.short_url}
                tag={post.tag}
                description={post.short_description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
