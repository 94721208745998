import axios from "axios";
const base_url = "https://genbiotec.com/admin/api/";

export const fetchHomeData = async () => {
  try {
    const response = await axios.get(`${base_url}home-data`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching home data:", error);
    throw error;
  }
};

export const fetchAboutData = async () => {
  try {
    const response = await axios.get(`${base_url}about-data`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching about data:", error);
    throw error;
  }
};


export const fetchMenuData = async () => {
  try {
    const response = await axios.get(`${base_url}division-menu`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu data:", error);
    throw error;
  }
};


export const fetchPhotoGalleryData = async () => {
  try {
    const response = await axios.get(`${base_url}photo-gallery`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching photo gallery data:", error);
    throw error;
  }
};

export const fetchVideoGalleryData = async () => {
  try {
    const response = await axios.get(`${base_url}video-gallery`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching video gallery data:", error);
    throw error;
  }
};
 

export const fetchBlogData = async () => {
  try {
    const response = await axios.get(`${base_url}blogs`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching blogs data:", error);
    throw error;
  }
};


export const fetchBlogDetailData = async (url) => {
  try {
    const response = await axios.get(`${base_url}blog/${url}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching blog details data:", error);
    throw error;
  }
};


export const fetchInfraData = async () => {
  try {
    const response = await axios.get(`${base_url}infrastructure`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching infra data:", error);
    throw error;
  }
};

export const fetchDivisionData = async (type) => {
  try {
    const response = await axios.get(`${base_url}division/${type}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching division details data:", error);
    throw error;
  }
};



export const fetchCareersData = async () => {
  try {
    const response = await axios.get(`${base_url}careers`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching careers data:", error);
    throw error;
  }
};

 

export const fetchContactData = async () => {
  try {
    const response = await axios.get(`${base_url}contact-us`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    throw error;
  }
};

export const fetchSiteData = async () => {
  try {
    const response = await axios.get(`${base_url}site-info`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching site info data:", error);
    throw error;
  }
};

export const sendFormData = async (value) => {
  try {
    const response = await axios.post(`${base_url}contact-enquiry`, value);
    return response.data.message;
  } catch (error) {
    console.error("Error sending form data:", error);
    throw error;
  }
};

export const sendCareerData = async (value) => {
  console.log("send value",value);
  try {
    const response = await axios.post(`${base_url}career-enquiry` , value,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error sending form data:", error);
    throw error;
  }
};

 