import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";

const StatsSection = ({ keyFeatures, img }) => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className=" w-full overflow-hidden hidden lg:block relative"
    >
       <LazyLoad height={200}  >
      <img src={img} alt="" className="w-full  " /></LazyLoad>
      {keyFeatures.map((feature, index) => {
        const leftPosition = `${8 + index * 31}%`; // Adjust spacing between items dynamically

        return (
          <div
            key={feature.id}
            className="absolute text-white top-[33%] flex items-center"
            style={{ left: leftPosition }} // Dynamically adjust position
          >
            <p className="!text-[75px] font-bold">
              {inView && (
                <CountUp
                  start={0}
                  end={parseInt(feature.count, 10)}
                  duration={4}
                />
              )}
              {feature.symbol}
            </p>
            <p className="w-[150px] ml-3">{feature.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default StatsSection;
