import React, { useEffect, useState } from "react";
import aboutbg from "../Assets/aboutbg.png";
import mapbg from "../Assets/mapbg.png";
import Button from "../Components/Buttons/Button";
import { fetchAboutData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const About = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchAboutData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching about data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about-us" },
  ];

  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div className="relative lg:mt-[100px] mt-[150px] h-[60vh] overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={data.banner.banner_url}
            alt=""
            className="object-cover w-full  h-full md:h-auto"
          />
          <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">About Us</h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <img
            src={aboutbg}
            alt=""
            className="absolute bottom-[-10px] object-cover w-full"
          />
        </div>
      )}
      {data?.aboutContent && (
        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl font-semibold mb-4">
            {data.aboutContent.title}
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: data.aboutContent.description,
            }}
          />
        </div>
      )}
      {data?.ourStories?.length > 0 && (
        <div>
          {data.ourStories.map((story, index) => (
            <div key={story.id}>
              <h3 className="text-3xl font-semibold text-center bg-[#F5F5F5] pt-16 pb-4">
                Our Stories
              </h3>
              {index % 2 === 0 ? (
                // First design for even indexes
                <div className="bg-[#F5F5F5]">
                  <div className="w-[75%] mx-auto pt-4 pb-6 text-center">
                    {/* Render the story description */}
                    <div
                      dangerouslySetInnerHTML={{ __html: story.description }}
                    ></div>
                  </div>
                </div>
              ) : (
                // Second design for odd indexes
                <div className="bg-black">
                  <div className="w-[85%] mx-auto md:flex">
                    <p className="text-white my-auto md:w-[70%]">
                      <div
                        dangerouslySetInnerHTML={{ __html: story.description }}
                      ></div>
                    </p>

                    {/* Conditionally render the image if it exists */}
                    {story.image_url && (
                      <img
                        src={story.image_url}
                        alt="Story Image"
                        className="md:w-[30%] object-cover"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {data?.ourValues?.length > 0 && (
        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl font-semibold mb-4">Our Values</h3>
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-8">
            {data.ourValues.map((item) => (
              <div className="px-6 py-10 bg-[#EFF0F7]">
                <h4 className="text-xl font-semibold mb-2">{item.title}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      )}

      {data?.ourTeams?.length > 0 && (
        <div className="w-[85%] mx-auto text-center pt-8 pb-16">
          <div className="w-[75%] mx-auto pb-8 text-center">
            <h3 className="text-3xl font-semibold mb-4">Our Leadership</h3>
            <p>
              Genbiotec (P) Ltd was established in the year 2000 by people with
              decades long experience of working in leading Pharma companies of
              the world. Genbiotec was started
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-8">
            {data.ourTeams.map((item) => (
              <div className="relative shadow-sm">
                <img
                  src={item.image_url}
                  alt="Person"
                  className="w-full h-[350px] rounded-lg object-cover"
                />
                <div className="absolute rounded-b-lg bottom-0 left-0 w-full bg-gradient-to-t from-gray-800 to-transparent text-white p-4">
                  <h3 className="text-base font-semibold">{item.title}</h3>
                  <p className="text-sm">{item.sub_title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {data?.aboutContent && (
        <div
          className="min-h-screen-height bg-cover bg-center   bg-no-repeat pt-16"
          style={{ backgroundImage: `url(${mapbg})` }}
        >
          <div className="w-[85%] mx-auto pb-16 md:flex  ">
            <div className="md:w-[40%] my-auto">
              <h3 className="text-3xl font-semibold mb-4">
                {data.aboutContent.alternate_title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.aboutContent.alternate_description,
                }}
              />
              <Button
                to={data.aboutContent.button_url}
                text={data.aboutContent.button_text}
              />
            </div>
            <div className="md:w-[60%] ">
              <img
                src={data.aboutContent.image_url}
                alt=""
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
