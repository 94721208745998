import React, { useEffect, useState } from "react";
import bg from "../Assets/aboutbg.png";
import { NavLink, useLocation } from "react-router-dom";
import BlogSection from "../Components/Tab/BlogTab";
import { fetchBlogData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
const Blog = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const path = useLocation();
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchBlogData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching blogs data:", error);
      }
    };

    loadData();
  }, []);
  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Blogs", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      <div className="relative lg:mt-[100px]  mt-[150px] h-[60vh] overflow-hidden top-0">
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
        <img
          src={data?.banner?.banner_url}
          alt=""
          className=" object-cover h-full md:h-auto  w-full"
        />
        <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
          <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">Our Blogs</h1>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <img
          src={bg}
          alt=""
          className="absolute bottom-[-10px] object-cover  w-full"
        />
      </div>
      {data?.selectedBlog && (
        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl mb-8 font-semibold"> Our Blogs</h3>
          {data?.selectedBlog && (
            <div className="md:flex ">
              <div className="md:w-[40%]">
                <img
                  src={data?.selectedBlog?.image_url}
                  alt=""
                  className="rounded-lg"
                />
              </div>
              <div className="md:w-[60%] md:ml-10 mt-6 md:mt-0">
                <div className="text-gray-600 text-sm mb-2">
                  {" "}
                  <span className="text-red mr-3">
                    {data?.selectedBlog?.tag}
                  </span>
                  {data?.selectedBlog?.reading_time}
                </div>
                <h2 className="text-xl font-semibold mb-2">
                  {data?.selectedBlog?.title}
                </h2>
                <p className="text-gray-800 text-sm mb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.selectedBlog?.short_description,
                    }}
                  />
                </p>
                <div className="flex   flex-col     ">
                  <p className="py-2 text-sm text-gray-600">
                    {data?.selectedBlog?.posted_date_formated}
                  </p>
                  <NavLink to={`/blog/${data?.selectedBlog?.short_url}`}>
                    <span className=" text-red hover:underline">Read More</span>
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {data?.blogs && (
        <div className="w-[85%] mx-auto pb-16">
          <h3 className="text-3xl mb-8 font-semibold"> More Articles</h3>
          <BlogSection data={data} />
        </div>
      )}
    </div>
  );
};

export default Blog;
