import React, { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const DetailSlider = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="flex justify-center items-start gap-4">
      {/* Main Image */}
      <div className="flex-1">
      <SlideshowLightbox className="w-full h-full" modalClose={"clickOutside"}>
        <img
          src={selectedImage.image_url}
          alt={selectedImage.title}
          className="w-full h-[304px] object-contain rounded-lg"
        />
        </SlideshowLightbox>
      </div>

      {/* Thumbnail Images */}
      <div className="flex flex-col gap-4">
        {images.map((image, index) => (
          <button
            key={index}
            className={`border-2 ${
              selectedImage.id === image.id
                ? "border-[#8C1A1C] rounded-lg"
                : "border-transparent"
            } focus:outline-none`}
            onClick={() => handleImageClick(image)}
          >
            <img
              src={image.image_url}
              alt={image.alt}
              className="w-[60px] h-[60px] object-cover rounded-lg"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default DetailSlider;
