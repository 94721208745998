import React, { useEffect, useState } from "react";
import photo from "../Assets/photobg.jpeg";
import bg from "../Assets/aboutbg.png";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

import { fetchPhotoGalleryData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const PhotoGallery = () => {
  const [selectedTab, setSelectedTab] = useState(null); // Initially null until data is fetched
  const [data, setData] = useState(null);
  const path = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchPhotoGalleryData();

        setData(result);
        setIsLoading(false);
        // Set the first tab as the initial selected tab
        if (result?.photos) {
          const firstTab = Object.keys(result.photos)[0];
          setSelectedTab(firstTab);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching photo gallery data:", error);
      }
    };

    loadData();
  }, []);

  let tabs = [];
  if (data?.photos) {
    tabs = Object.keys(data.photos);
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const filteredImages = data?.photos[selectedTab];

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Photo Gallery", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div className="relative lg:mt-[100px]  mt-[150px] h-[60vh] overflow-hidden top-0">
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={data?.banner?.banner_url}
            alt=""
            className="object-cover h-full md:h-auto  w-full"
          />
          <div className="absolute w-[80%] mx-auto z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">Photo Gallery</h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <img
            src={bg}
            alt=""
            className="absolute bottom-[-10px] object-cover w-full"
          />
        </div>
      )}
      {tabs?.length > 0 && (
        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl font-semibold mb-8">Photo Gallery</h3>

          {/* Tab Navigation */}
          <div className="flex mb-10 py-2">
            {tabs?.map((tab) => (
              <button
                key={tab}
                className={`py-1 px-4 text-center text-sm font-medium transition-colors duration-300 ease-in-out ${
                  selectedTab === tab
                    ? "bg-red text-white rounded-md"
                    : "border-transparent text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.toUpperCase()}
              </button>
            ))}
          </div>

          {/* Image Grid */}
          {/* Image Grid with Lightbox */}
          <SlideshowLightbox
            className="grid grid-cols-1 md:grid-cols-3 gap-6"
            lightboxIdentifier="gallery-lightbox"
            showThumbnails
            modalClose={"clickOutside"}
            framework="tailwind" // Optional: Use Tailwind classes for styling
            images={filteredImages?.map((image) => image.image_url)} // Pass the array of image URLs
          >
            {filteredImages?.map((image, imageIndex) => (
              <div key={imageIndex} className="photo-card">
                <img
                  className="h-[350px] object-cover w-full shadow-md rounded-lg"
                  src={image.image_url}
                  alt={image.title}
                  data-lightboxjs="gallery-lightbox" // Identifier for Lightbox images
                />
              </div>
            ))}
          </SlideshowLightbox>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
