import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendFormData } from "../../Api/Api";
const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string().required("MobileNumber is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  subject: Yup.string().required("Subject is required"),

  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
          subject: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            console.log("Form 1 values:", values);

            // Send the form data to the server
            const result = await sendFormData(values);

            // Display success toast
            toast.success(result);

            // Reset the form
            resetForm();
          } catch (error) {
            // Display error toast
            toast.error("Form submission failed!");
            console.error("Form submission error:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block font-medium">
                  First Name <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="firstName"
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="lastName" className="block font-medium">
                  Last Name <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="lastName"
                  type="text"
                  className="block w-full p-2 border focus:outline-none border-gray-300 rounded"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="mobileNumber" className="block font-medium">
                  MobileNumber <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="mobileNumber"
                  type="text"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="email" className="block font-medium">
                  Email <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="email"
                  type="email"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="subject" className="block font-medium">
                  Subject <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="subject"
                  type="text"
                  className="block w-full p-2 focus:outline-none border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="text-red text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <div>
                <label htmlFor="message" className="block font-medium">
                  Describe Yourself <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="message"
                  as="textarea"
                  rows="4"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div className="text-end mt-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="border border-red hover:border-red py-2 px-4 rounded-md text-sm font-medium transition-colors duration-300 ease-in-out transform hover:bg-red hover:text-white  active:opacity-70"
                >
                  {isSubmitting ? "Sending..." : "Sent Message"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ToastContainer />
    </div>
  );
};

export default ContactForm;
