import React from "react";

import { NavLink } from "react-router-dom";

const TermsAndConditions = ({ data }) => {
  return (
    <div>
      <div className="w-[85%] mx-auto my-14">
        {data && (
          <h1 className="text-3xl font-semibold  uppercase mb-4">
            TERMS & CONDITIONS
          </h1>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </div>
    </div>
  );
};

export default TermsAndConditions;
