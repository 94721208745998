import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FaClock, FaBriefcase } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import JobForm from "../Forms/JobForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccordionItem = ({
  job,
  isOpen,
  toggleOpen,
  isModalOpen,
  isTransitioning,
  openModal,
  closeModal,
  jobSelected,
  showToast,
}) => {
  return (
    <div className="border-b">
      <button
        className="w-full flex justify-between items-center text-left md:p-4 p-2  focus:outline-none"
        onClick={toggleOpen}
      >
        <div>
          <p className="text-lg font-medium uppercase"> {job.title}</p>
          <p className="text-sm">
            We are looking for a {job.title} to join our team
          </p>
          <div className="flex space-x-4 mt-3">
            {/* Full Time Button */}
            <button
              className="flex items-center px-4 py-1 border border-gray-400 rounded-full text-gray-700 hover:bg-gray-100"
              onClick={(e) => e.stopPropagation()}
            >
              {job.job_type} <FaClock className="ml-2 text-gray-500" />
            </button>

            <button
              className="flex items-center px-4 py-1 border border-gray-400 rounded-full text-gray-700 hover:bg-gray-100"
              onClick={(e) => e.stopPropagation()}
            >
              {job.experience_required}{" "}
              <FaBriefcase className="ml-2 text-gray-500" />
            </button>
          </div>
        </div>

        <IoIosArrowDown />
      </button>
      {isOpen && (
        <div className="md:p-4 p-2">
          <p className="uppercase text-sm font-medium pt-6 pb-2">Location:</p>
          <p className="text-start text-sm">{job.location}</p>
          <p className="uppercase text-sm font-medium py-6">Description:</p>
          <div
            dangerouslySetInnerHTML={{
              __html: job.description,
            }}
          />
          <p className="uppercase text-sm font-medium py-6">
            Responsibilities:
          </p>

          <div
            dangerouslySetInnerHTML={{
              __html: job.responsibilities,
            }}
          />
          <p className="uppercase text-sm font-medium py-6">Requirements:</p>
          <div
            dangerouslySetInnerHTML={{
              __html: job.requirements,
            }}
          />

          <div className="text-end my-6">
            <button onClick={() => openModal(job)}>
              <span className="border border-black hover:border-red py-2 px-4 rounded-md text-sm font-medium transition-colors duration-300 ease-in-out transform hover:bg-red hover:text-white  active:opacity-70">
                APPLY NOW
              </span>
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div
          className={`fixed inset-0  bg-black bg-opacity-60 flex justify-center items-center z-[999] transition-opacity duration-300 ${
            isTransitioning ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`relative bg-white p-10 rounded-lg     w-[80%] max-h-[80vh] overflow-y-auto transition-transform duration-300 ${
              isTransitioning ? "scale-100" : "scale-50"
            }`}
          >
            <button
              className="absolute top-2 right-2 text-black"
              onClick={closeModal}
            >
              <AiOutlineClose size={18} />
            </button>
            <div className=" text-center  ">
              <h3 className="text-3xl font-semibold mb-3">
                Apply for this job
              </h3>
              <p className="mb-6">
                {" "}
                <span className="font-semibold ">JOb Title :</span>{" "}
                {jobSelected.title}
              </p>
            </div>

            <JobForm
              id={jobSelected.id}
              closeModal={closeModal}
              showToast={showToast}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const JobsAccordion = ({ jobs, title, description }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const [jobSelected, setJobSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const openModal = (job) => {
    setIsModalOpen(true);
    setJobSelected(job);
    setTimeout(() => setIsTransitioning(true), 10);
  };

  const closeModal = () => {
    setIsTransitioning(false);
    setTimeout(() => setIsModalOpen(false), 300);
  };

  const showToast = (message, type = "success") => {
    if (type === "success") {
      toast.success(message);
    } else if (type === "error") {
      toast.error(message);
    }
  };
  return (
    <div className="md:w-[85%] mx-auto">
      <div className="text-center mb-10">
        <h3 className="text-3xl font-semibold mb-8">{title}</h3>
        <p>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </p>
      </div>
      {jobs.map((job, index) => (
        <AccordionItem
          key={index}
          job={job}
          isOpen={openIndex === index}
          toggleOpen={() => toggleOpen(index)}
          isModalOpen={isModalOpen}
          isTransitioning={isTransitioning}
          openModal={openModal}
          closeModal={closeModal}
          jobSelected={jobSelected}
          showToast={showToast}
        />
      ))}
      <ToastContainer />
    </div>
  );
};

export default JobsAccordion;
