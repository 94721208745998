import React from "react";
import { NavLink } from "react-router-dom";

const Button = ({ to, text }) => {
  return (
    <div className="my-3">
      <NavLink to={to}>
        <span className="border-red border py-2 px-6 rounded-md text-base font-medium transition-colors duration-300 ease-in-out transform hover:bg-red hover:text-white  active:opacity-70">
          {text}
        </span>
      </NavLink>
    </div>
  );
};

export default Button;
