import React, { useEffect, useState } from "react";
import photo from "../Assets/infra.jpeg";
import bg from "../Assets/aboutbg.png";
import map from "../Assets/inframap.png";
import { NavLink, useLocation } from "react-router-dom";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import BlogCard from "../Components/Cards/BlogCards";
import { fetchInfraData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const InfraStructure = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const path = useLocation();
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchInfraData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching infra data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Global Presence", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      <div className="relative lg:mt-[100px]  mt-[150px] h-[60vh] overflow-hidden top-0">
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
        <img
          src={data?.banner?.banner_url}
          alt=""
          className=" object-cover h-full md:h-auto  w-full"
        />
        <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
          <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">Global Presence</h1>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <img
          src={bg}
          alt=""
          className="absolute bottom-[-10px] object-cover w-full"
        />
      </div>
      {data?.infrastructure && (
        <div className="w-[85%] mx-auto pt-16">
          <h3 className="text-3xl font-semibold mb-8">
            {data.infrastructure.title}
          </h3>
          <p className=" mb-4">
            <div
              dangerouslySetInnerHTML={{
                __html: data.infrastructure.description,
              }}
            />
          </p>
          <NavLink to={`/${data.infrastructure.button_url}`}>
            <span className="text-[#01A5BF] font-semibold pb-16">
              {data.infrastructure.button_text}{" "}
            </span>
          </NavLink>

          <img
            src={data.infrastructure.image_url}
            alt=""
            className="pb-16 object-center  mx-auto"
          />
        </div>
      )}

      {data?.contactInfo && (
        <div className="w-[85%] mx-auto pb-16 mt-4 text-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex items-center flex-col">
              <div className=" bg-[#F9F9F9]  rounded-full p-2 w-max">
                <FaEnvelope className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">
                {data.contactInfo.email_title}
              </h3>
              <p className="text-sm py-1">{data.contactInfo.email_sub_title}</p>
              <p className="text-sm text-red m-0">
                {" "}
                <a href={`mailto:${data.contactInfo.email_id}`}>
                  {data.contactInfo.email_id}
                </a>
              </p>
            </div>

            <div className="flex items-center  flex-col">
              <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                <FaLocationDot className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">
                {data.contactInfo.address_title}
              </h3>
              <p className="text-sm py-1">
                {data.contactInfo.address_sub_title}
              </p>
              <p className="text-sm text-red m-0">{data.contactInfo.address}</p>
            </div>

            <div className="flex items-center  flex-col">
              <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                <FaPhone className="  text-[#F98083]  " />
              </div>

              <h3 className="text-base  mt-3">
                {data.contactInfo.phone_title}{" "}
              </h3>
              <p className="text-sm py-1">{data.contactInfo.phone_sub_title}</p>
              <p className="text-sm text-red m-0">
                <a href={`tel:${data.contactInfo.phone_number}`}>
                  {" "}
                  {data.contactInfo.phone_number}
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {data?.blogs?.length > 0 && (
        <div className="w-[85%] mx-auto pb-16">
          <h3 className="text-3xl mb-8 font-semibold"> Our Blogs</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-8">
            {data?.blogs?.map((post) => (
              <BlogCard
                key={post.id}
                imageSrc={post.image_url}
                title={post.title}
                readTime={post.readTime}
                date={post.posted_date_formated}
                url={post.short_url}
                tag={post.tag}
                description={post.short_description}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InfraStructure;
