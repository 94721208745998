import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendCareerData } from "../../Api/Api";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  educational_qualification: Yup.string().required(
    "  Qualification is required"
  ),
  experience: Yup.string().required("Experience is required"),
  location: Yup.string().required("Location is required"),
  message: Yup.string().required("Describe Yourself is required"),
  resume: Yup.mixed().required("Resume/CV is required"),
});

const JobForm = ({ id, closeModal, showToast }) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
          educational_qualification: "",
          location: "",
          experience: "",
          message: "",
          resume: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            console.log("Form 1 values:", values);

            // Send the form data to the server
            const result = await sendCareerData({ ...values, job: id });

            showToast("Form submission success!", "success");
            // Reset the form
            resetForm();
            closeModal();
          } catch (error) {
            // Display error toast
            showToast("Form submission failed!", "error");
            console.error("Form submission error:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Other form fields */}
              <div>
                <label htmlFor="firstName" className="block font-medium">
                  First Name <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="firstName"
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="lastName" className="block font-medium">
                  Last Name <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="lastName"
                  type="text"
                  className="block w-full p-2 border focus:outline-none border-gray-300 rounded"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="mobileNumber" className="block font-medium">
                  Mobile Number <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="mobileNumber"
                  type="text"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="email" className="block font-medium">
                  Email <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="email"
                  type="email"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label
                  htmlFor="educational_qualification"
                  className="block font-medium"
                >
                  Qualification <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="educational_qualification"
                  type="text"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="educational_qualification"
                  component="div"
                  className="text-red text-sm"
                />
              </div>

              <div>
                <label htmlFor="location" className="block font-medium">
                  Location
                </label>
                <Field
                  name="location"
                  type="text"
                  className="block w-full p-2 focus:outline-none border border-gray-300 rounded"
                />
              </div>

              <div>
                <label htmlFor="experience" className="block font-medium">
                  Experience <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="experience"
                  type="text"
                  className="block w-full p-2 focus:outline-none border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="experience"
                  component="div"
                  className="text-red text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <div>
                <label htmlFor="message" className="block font-medium">
                  Describe Yourself <span className="text-red">*</span>{" "}
                </label>
                <Field
                  name="message"
                  as="textarea"
                  rows="4"
                  className="block w-full focus:outline-none p-2 border border-gray-300 rounded"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-red text-sm"
                />
              </div>
              {/* Resume and Attach/Dropbox/Paste */}
              <div className="md:col-span-2 flex mt-4">
                <label htmlFor="resume" className="block font-medium">
                  Resume/CV <span className="text-red">*</span>
                </label>
                <div>
                  <span
                    onClick={handleClick}
                    className="text-blue-500 cursor-pointer ml-6"
                  >
                    Attach / Dropbox / Paste
                  </span>
                  <input
                    ref={fileInputRef}
                    type="file"
                    name="resume"
                    accept=".pdf" // Limit file selection to PDF
                    className="hidden"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("resume", file);
                      setSelectedFile(file); // Set the selected file for display
                    }}
                  />
                  <ErrorMessage
                    name="resume"
                    component="div"
                    className="text-red text-sm ml-6"
                  />
                </div>
              </div>

              {/* Show selected file name */}
              {selectedFile && (
                <div className="md:col-span-2 mt-2">
                  <p className="text-sm">
                    <strong>Selected File:</strong> {selectedFile.name}
                  </p>
                  <a
                    href={URL.createObjectURL(selectedFile)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text-blue-500 underline">Preview PDF</span>
                  </a>
                </div>
              )}

              <div className="text-end mt-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="border border-black hover:border-red py-2 px-4 rounded-md text-sm font-medium transition-colors duration-300 ease-in-out transform hover:bg-red hover:text-white active:opacity-70"
                >
                  {isSubmitting ? "Sending..." : " Submit your application"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>{" "}
    </div>
  );
};

export default JobForm;
