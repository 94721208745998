import React, { useEffect, useState } from "react";
import photo from "../Assets/video.gif";
import bg from "../Assets/aboutbg.png";
import { fetchVideoGalleryData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const VideoGallery = () => {
  const [selectedTab, setSelectedTab] = useState(null); // Initially null until data is fetched
  const [data, setData] = useState(null);
  const path = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchVideoGalleryData();

        setData(result);
        setIsLoading(false);
        // Set the first tab as the initial selected tab
        if (result?.videos) {
          const firstTab = Object.keys(result.videos)[0];
          setSelectedTab(firstTab);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching video gallery data:", error);
      }
    };

    loadData();
  }, []);

  let tabs = [];
  if (data?.videos) {
    tabs = Object.keys(data.videos);
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const filteredVideos = data?.videos[selectedTab];

  if (isLoading) {
    return <Loader />;
  }
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Video Gallery", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div className="relative lg:mt-[100px]   mt-[150px] h-[60vh] overflow-hidden ">
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={data?.banner?.banner_url}
            alt="Video thumbnail"
            className="object-cover h-full md:h-auto w-full"
          />
          <div className="absolute w-[80%] mx-auto  z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[30px]">Video Gallery</h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <img
            src={bg}
            alt="Background"
            className="absolute bottom-[-10px] object-cover w-full"
          />
        </div>
      )}
      {tabs?.length > 0 && (
        <div className="w-[85%] mx-auto py-16">
          <h3 className="text-3xl font-semibold mb-8">Video Gallery</h3>

          {/* Tab navigation */}
          <div className="flex mb-10 py-2">
            {tabs?.map((tab) => (
              <button
                key={tab}
                className={`py-1 px-4 text-center text-sm font-medium transition-colors duration-300 ease-in-out ${
                  selectedTab === tab
                    ? "bg-red text-white rounded-md"
                    : "border-transparent text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.toUpperCase()}
              </button>
            ))}
          </div>

          {/* Video display */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredVideos?.map((video, index) => (
              <div key={index} className="video-wrapper">
                <iframe
                  width="100%"
                  height="315"
                  src={video.video_url}
                  title={`YouTube video player ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="rounded-lg"
                ></iframe>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
